<template>
    <div class="page-container">
        <div class="content-container">
            <div class="input-wrapper">
                <div class="ticket-container round-container">
                    <TicketDepiction class="ticket-depiction" :ticket="selectedproduct" :selectable="false">
                        <div class="ticket-content">
                            <div class="ticket-picture" v-if="croppedpicture">
                                <img :src="croppedpicture" class="customer-image">
                            </div>
                            <div>
                                <!-- <label class="product-name">{{ selectedproduct.original.Produktbezeichnung }}</label> <p:outputLabel id="pruefungProduktwahlPanelGrid_Produkt"/> -->
                                <div class="ticket-info-wrapper">
                                    <div class="ticket-info">
                                        <div class="customer">
                                            <label>{{ $t('general.customer') }}</label>
                                            <div id="Y2hlY2trdW5kZW5hbWU=" class="name">{{ getFullDisplayname(customer) }}</div>
                                            <div id="Y2hlY2trdW5kZWdlYnVydHN0YWc=" class="birthday">{{ $t('general.birthdate-short') + ': ' + customer.birthdate }}</div>
                                            <div id="Y2hlY2trdW5kZXRlbGVmb24=" class="phone">{{ $t('general.phone-short') + ': ' + customer.phone }}</div>
                                            <div id="Y2hlY2trdW5kZXN0cmFzc2U=" class="street"> {{ `${customer.street} ${customer.housenr} ${customer.addressinfo}`}}</div>
                                            <div id="Y2hlY2trdW5kZW9ydA==" class="city">{{ `${customer.zip} ${customer.city} ${customer.district}` }}</div>
                                            <div id="Y2hlY2trdW5kZWxhbmQ=" class="country">{{ customer.country.displayname }}</div>
                                        </div>
                                    </div>
                                    <div class="ticket-info">
                                        <div class="cardowner" v-if="customer.differentcardowner">
                                            <label>{{ $t('general.card-owner') }}</label>
                                            <div id="Y2hlY2tma2luYW1l" class="name">{{ getFullDisplayname(cardowner) }}</div>
                                            <div id="Y2hlY2tma2lnZWJ1cnRzdGFn" class="birthday">{{ $t('general.birthdate-short') + ': ' + cardowner.birthdate }}</div>
                                            <div id="Y2hlY2tma2l0ZWxlZm9u" class="phone">{{ $t('general.phone-short') + ': ' + cardowner.phone }}</div>
                                            <div id="Y2hlY2tma2lzdHJhc3Nl" class="street"> {{ `${cardowner.street} ${cardowner.housenr} ${cardowner.addressinfo}`}}</div>
                                            <div id="Y2hlY2tma2lvcnQ=" class="city">{{ `${cardowner.zip} ${cardowner.city} ${cardowner.district}` }}</div>
                                            <div id="Y2hlY2tma2lsYW5k" class="country">{{ cardowner.country.displayname }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TicketDepiction>
                </div>

                <div class="code-container round-container" v-if="(voucherfield && voucherfield.Anzeigen === 'true') || voucherurl">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('complete-order-page.code') }}</label>
                        </div>

                        <div class="info-container input full">
                            <label>{{ $t('complete-order-page.code-hint') }}</label>
                        </div>

                        <div class="input">
                            <TextInput idstub="YWt0aW9uc2NvZGU=" class="code-input" :class="{ 'valid' : voucherverified === true, 'invalid' : voucherverified === false, }" ref="voucher" :error="$v.voucher.$error" v-model="voucher" :label="voucherfield.externeBezeichnung" :required="$v.voucher.$params.required !== undefined"/>
                        </div>

                        <div class="input" v-if="voucherurl">
                            <button id="aktionscode" @click="checkVoucher" class="code-button">{{ $t('complete-order-page.buttons.check-code') }}</button>
                        </div>

                        <div v-if="this.voucherinfo" class="input full voucherinfo" :class="{ 'invalid' : voucherverified === false }">
                            <label id="YWt0aW9uc2NvZGVpbmZv">{{ voucherinfo }}</label>
                        </div>
                    </div>
                </div>

                <div class="legal-container round-container">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('complete-order-page.legal-hints') }}</label>
                        </div>

                        <div class="info-container">
                            <label>{{ legalhint1 }}</label>
                        </div>

                        <div id="bGVnYWxsaW5rcw==" class="input full link" v-for="link in legallinks" :key="link.id">
                            <a target="_blank" :href="link.link">{{ link.display }}</a>
                        </div>

                        <CheckboxInput id="YmVkaW5ndW5nZW4=" class="input full" :error="$v.termsaccepted.$error" v-model="termsaccepted" :text="$t('complete-order-page.terms-accepted-hint') + ' ' + (legalhint2 ? '*¹' : '' ) + (legalhint3 ? '*²' : '' )"/>
                        <span class="input"/>

                        <div class="info-container input full" v-if="legalhint2">
                            <label id="bGVnYWwx">{{ legalhint2 }}</label>
                        </div>

                        <div class="info-container input full" v-if="legalhint3">
                            <label id="bGVnYWwy">{{ legalhint3 }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="information-wrapper">
                <TicketInformationContainer :product="selectedproduct" :relation="relation" />

                <button id="order" class="complete-container round-container" @click="order()" :disabled="!done">
                    <label>{{ $t('complete-order-page.order') }}</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import {
    required,
} from 'vuelidate/lib/validators'

import TextInput from '../components/systems/elements/TextInput.vue'
import CheckboxInput from '../components/systems/elements/CheckboxInput.vue'
import TicketDepiction from '../components/systems/elements/TicketDepiction.vue'
import TicketInformationContainer from '../components/systems/TicketInformationContainer.vue'

import 'vue-awesome/icons/money-check'
import 'vue-awesome/icons/file'
import 'vue-awesome/icons/asterisk'
import 'vue-awesome/icons/exclamation'

export default {
    name: 'CompleteOrderPage',
    data() {
        return {
            // hints 1 through 3 and the contacthint all come from langugage files:
            legalhint1: this.$t('complete-order-page.legalhint1'),
            contacthint: this.$t('complete-order-page.contacthint'),
            mailcontact: false,
            postcontact: false,
            termsaccepted: false,
            allowcontact: false,
            titles: [],
            salutations: [],
            countries: [],
            voucher: undefined,
            voucherverified: undefined,
            voucherinfo: undefined
        }
    },
    validations() {
        let validators = {
            voucher: {},
            termsaccepted: {
                checked: value => value === true
            }
        }

        // handle field configurations
        // only validate the voucher it it's free field config says so
        // AND if no code-number-server is defined, else the user could not
        // order without a code
        if (this.voucherfield && (this.voucherfield.Pflichtfeld === 'true') && !this.voucherurl) {
            validators.voucher = {
                required
            }
        } else if (this.voucherurl) {
            validators.voucher = {
                checked: value => {
                    // if a voucher shall be used, it needs to be verified
                    if (value && value.trim() !== '') {
                        return this.voucherverified === true
                    } else {
                        // if there is no code, we don't need to check it
                        return true
                    }
                }
            }
        }

        return validators
    },
    computed: {
        done() {
            return this.termsaccepted
        },
        legallinks() {
            return this.$root.$data.systemconfiguration.legallinklist.map(entry => {
                return {
                    id: entry.ID,
                    display: entry.Name,
                    link: entry.Link,
                    original: entry
                }
            })
        },
        selectedproduct() {
            return this.$root.$data.product
        },
        relation() {
            return this.$root.$data.relation
        },
        customer() {
            return this.$root.$data.customer
        },
        cardowner() {
            return this.$root.$data.cardowner
        },
        bankaccountowner() {
            return this.$root.$data.bankaccountowner
        },
        croppedpicture() {
            return this.$root.$data.croppedpicture
        },
        voucherfield() {
            // FeldID === FeldKonfigArt, 4 === Aktionscode
            return this.getFieldConfig(4)
        },
        allowaccountcreation() {
            return this.$root.$data.systemconfiguration.anlageassistent.allowaccountcreation
        },
        voucherurl() {
            return this.$root.$data.systemconfiguration.configuration.CodenummernserverURL
        },
        legalhint2() {
            const fallback = this.$t('complete-order-page.legalhint2')
            const text = this.getText(16) // 16 === RECHTLICHES_HINWEIS2

            if (text || fallback) {
                return '*¹ ' + (text || fallback)
            }

            return ''
        },
        legalhint3() {
            const fallback = this.$t('complete-order-page.legalhint3')
            const text = this.getText(17) // 17 === RECHTLICHES_HINWEIS3

            if (text || fallback) {
                return '*² ' + (text || fallback) 
            }

            return ''
        },
    },
    methods: {
        getText(variant) {
            var result = undefined
            if (this.selectedproduct.original.Infotexte
                    && this.selectedproduct.original.Infotexte.TOWSInfotext) {
                const text = this.selectedproduct.original.Infotexte.TOWSInfotext.find(entry => entry.Variante === variant)
                if (text) result = text.Text
            }
            return result
        },
        getFullDisplayname(person) {
            let result = ""
            if (person.salutation.displayid !== -1) result += person.salutation.displayname + ' '
            if (person.title.displayid !== -1) result += person.title.displayname + ' '
            result += person.firstname + ' '
            result += person.lastname
            return result
        },
        getFullDisplayAddress(person) {
            let result = ""
            result += person.street + ' ' + person.housenr + person.addressinfo + ', '
            result += person.zip + ' ' + person.city + ' ' + person.district + ', '
            result += person.country.original
            return result
        },
        getAdditionalInfoDisplay(person) {
            let result = ""
            result += this.$t('general.birthdate-short') + ': ' + person.birthdate
            if (person.phone) {
                result += ', ' + this.$t('general.phone-short') + ': ' + person.phone
            }
            return result
        },
        getFieldConfig(fieldid) {
            return this.$root.$data.systemconfiguration.fields?.find(field => field.FeldID === fieldid)
        },
        mapGermanDateToJsDate(gerdate) {
            if (!gerdate || typeof gerdate !== 'string' || gerdate.trim() === '') {
                return gerdate
            }

            const dateparts = gerdate.match(/(\d+)/g)
            const birthdate = new Date(dateparts[2], dateparts[1]-1, dateparts[0])

            return birthdate
        },
        checkVoucher() {
            if (!this.voucher || this.voucher.trim() === '') {
                return
            }

            this.$root.$data.loading = true

            let checkurl = Vue.middleware()
            checkurl += '/voucher/' + this.voucher
            checkurl += '?zip=' + this.customer.zip
            checkurl += '&timestamp=' + Math.floor(Date.now() / 1000)

            const checkbody = {
                url: this.voucherurl
            }

            axios.post(checkurl, checkbody)
            .then(response => {
                const success = response.data.Erfolg

                if (!success) {
                    Vue.$toast.error(this.$t('complete-order-page.invalid-voucher'))
                }

                // TODO: locale?
                let locale = 'de'

                if (locale === 'de') {
                    this.voucherinfo = response.data.TextDE
                } else if (locale === 'en') {
                    this.voucherinfo = response.data.TextEN
                }

                this.voucherverified = success
                this.$root.$data.loading = false
            })
            .catch(err => {
                console.log(err)
                Vue.$toast.error(this.$t('complete-order-page.voucher-not-verified'))
                this.$root.$data.loading = false
            })

        },
        order: async function() {
            this.$v.voucher.$touch()

            if (this.$v.$anyError) {
                this.$refs['voucher'].$el.scrollIntoView({ behavior: 'smooth' })
                return
            }

            this.$root.$data.loading = true

            // the usertoken was possibly passed in case of existing user
            let usertoken = this.customer.nutzerIdToken

            // if an initial login should be provided
            // we create a customer and write the token to the contract
            // ensuring that TO can trigger the initial login process

            // TODO: create nps user for contact if with login
            if (this.allowaccountcreation && this.customer.createaccount) {

                //create keycloak user first
                let createuseraccounturl = Vue.middleware()
                createuseraccounturl += '/useraccount/user'
                const createuseraccountbody = {
                    email:      this.customer.email,
                    firstname:  this.customer.firstname,
                    lastname:   this.customer.lastname,
                }

                let createuseraccountresponse

                try {
                    createuseraccountresponse = await axios.post(createuseraccounturl, createuseraccountbody)
                    if(createuseraccountresponse.data.status === 201 && createuseraccountresponse.data.id) {

                        //create nps user
                        const userid = createuseraccountresponse.data.id

                        let createuserurl = Vue.middleware()
                        createuserurl += '/abo-online/external-users/'
                        createuserurl += userid

                        const createuserbody = {
                            email:      this.customer.email,
                            firstname:  this.customer.firstname,
                            lastname:   this.customer.lastname,
                            salutation: this.customer.salutation.displayid !== -1 ? this.customer.salutation.displayname : undefined,
                            title:      this.customer.title.displayid !== -1 ? this.customer.title.displayname : undefined,
                            birthdate:  this.mapGermanDateToJsDate(this.customer.birthdate),
                            phone:      this.customer.phone
                        }

                        const createuserresponse = await axios.patch(createuserurl, createuserbody)
                        usertoken = createuserresponse.data.userToken

                    } else {
                        Vue.$toast.warning(this.$t('complete-order-page.errors.account-creation-error'))
                    }
                } catch {
                    Vue.$toast.warning(this.$t('complete-order-page.errors.account-creation-error'))
                }
            }

            let orderurl = Vue.middleware()
            orderurl += '/contracts'

            let body = {
                customer: this.customer,
                cardowner: this.cardowner,
                bankaccountowner: this.bankaccountowner,
                product: this.selectedproduct,
                relation: this.relation,
                picture: this.croppedpicture,
                mailcontact: this.mailcontact,
                postcontact: this.postcontact,
                allowcontact: this.allowcontact,
                documents: [],
                usertoken: usertoken
            }

            body.customer.birthdate = this.mapGermanDateToJsDate(this.customer.birthdate)

            if (body.cardowner) {
                body.cardowner.birthdate = this.mapGermanDateToJsDate(this.cardowner?.birthdate)
            }

            if (body.bankaccountowner) {
                body.bankaccountowner.birthdate = this.mapGermanDateToJsDate(this.bankaccountowner?.birthdate)
            }

            return axios.post(orderurl, body)
            .then(response => {
                this.$root.$data.loading = false

                if (response.data.status.ErrorCode === 72) {
                    Vue.$toast.error(this.$t('complete-order-page.wrong-email'))
                } else if (response.data.status.ErrorCode !== 0) {
                    Vue.$toast.error(this.$t('complete-order-page.order-failed'))
                    console.log(response.data.status.ErrorMsg)
                } else {
                    this.$router.push({ name:'done' })
                }
            })
            .catch(err => {
                Vue.$toast.error(this.$t('complete-order-page.order-failed'))
                console.log(err)
                this.$root.$data.loading = false
            })
        }
    },
    watch: {
        allowcontact(newvalue) {
            this.mailcontact = newvalue
            this.postcontact = newvalue
        },
        termsaccepted(newvalue) {
            if (newvalue && (document.body.clientWidth >= 992)) {
                document.getElementById('router-view').scrollTo(0,0)
            }
        },
        voucher(newvalue) {
            this.voucherverified = undefined
            this.voucherinfo = undefined
            this.customer.voucher = newvalue
        }
    },
    components: {
        TicketInformationContainer,
        CheckboxInput,
        TicketDepiction,
        TextInput
    }
}
</script>

<style scoped>
.ticket-picture {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-picture > .customer-image {
    width: 100px;
    height: 150px;
    background-color: #eee;
    border: 1px solid #999;
    border-radius: 8px;
}

.personal-data-container {
    width: 100%;
    background-color: #fff;
}

.ticket-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 30px;
}

.ticket-depiction > .ticket-info-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    align-items: center;
}

.ticket-info-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
}

.ticket-info-wrapper > .ticket-info {
    width: fit-content;
    text-align: left;
    min-width: 100px;
    margin-left: 10px;
}

.ticket-info > .customer,
.ticket-info > .cardowner {
    margin-top: 10px;
    padding: 10px;
    font-size: 1.1em;
    max-width: 200px;
}

.ticket-info > .customer > label,
.ticket-info > .cardowner > label {
    font-weight: bold;
}

.ticket-info > .customer > .street,
.ticket-info > .cardowner > .street {
    margin-top: 15px;
}

.cardowner > div,
.customer > div {
    margin-top: 5px;
    margin-left: 10px;
}

.ticket-depiction > .product-name {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 10px;
}

.content-container {
    display: flex;
    flex-wrap: wrap;
}

.content-container > .input-wrapper {
    flex: 1 1 100%;
}

.input-wrapper > .ticket-container {
    width: 100%;
}

.ticket-container > .ticket-wrapper {
    position: relative;
    width: 90%;
    background-color: #ccc;
    min-height: 100px;
    margin: 20px auto;
}

.ticket-container > .ticket-wrapper:before,
.ticket-container > .ticket-wrapper:after {
    position: absolute;
    content: " ";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
    top: calc(50% /* half the product height */ - 20px /* half the dot height */);
}

.ticket-container > .ticket-wrapper:before {
    left: -20px;
}

.ticket-container > .ticket-wrapper:after {
    right: -20px;
}

.input-wrapper > .double-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
    align-items: center;
}

.contact-container,
.code-container,
.legal-container {
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.code-container {
    min-height: 175px;
}

.contact-container >>> .input-wrapper {
    padding-top: 0;
}

.ticket-wrapper > .ticket {
    width: 100%;
    height: 100%;
    padding: 5px 20px;
}

.input-wrapper > .input-container {
    background: #fff;
    border-radius: 8px;
}

.input-wrapper > .input-container:not(:first-child) {
    margin-top: 2em;
}

.input.header {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.input.link {
    height: 20px;
    text-align: left;
    padding-left: 10px;
}

.content-container > .information-wrapper {
    min-width: 300px;
    width: 100%;
    margin-top: 2em;
    user-select: none;
}

.information-wrapper > .complete-container {
    margin-top: 20px;
    height: 75px;
    background: #4e9c81;
    width: 100%;
    color:#fff;
    margin-left: 0;
}

.information-wrapper > .complete-container:disabled {
    background: #4e9c8166;
}

.information-wrapper > .complete-container:not(:disabled):hover {
    background: #3e7c67;
}

.complete-container > label {
    display: block;
    font-weight: bold;
    font-size: 1.5em;
}

.panel-container > .next-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.next-container > .next-button {
    margin-bottom: 15px;
    padding: 10px 15px;
    font-size: 1.3em;
    font-weight: bold;
    color: #fff;
    background-color: #064A6C;
    display: flex;
}

.next-container > .next-button:hover {
    background-color: #064A6Ccc;
}

.next-button > svg {
    margin-left: 5px;
    fill: #fff;
}

.info-container {
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    padding-left: 10px;
}

.info-container > .info {
    white-space: pre-wrap;
    width: 100%;
}

.image-container {
    text-align: left;
}

.image-container > .customer-image {
    width: 150px;
    height: 225px;
    margin-bottom: 2em;
    background-color: #eee;
    border: 1px solid #999;
    border-radius: 8px;
}

.input > .code-input {
    position: relative;
    padding-top: 6px;
}

.input > .code-input.valid::after {
    position: absolute;
    right: 10px;
    top: 5px;
    bottom: 0;
    color: green;
    font-size: 1.75em;
    content: '\2713';
}

.input > .code-input.invalid::after {
    position: absolute;
    right: 10px;
    top: 5px;
    bottom: 0;
    color: red;
    font-size: 1.75em;
    content: '\2717';
}

.input > .code-button {
    background-color: #064A6C;
    color: #fff;
    padding: 0 10px;
    margin-left: 0;
}

.voucherinfo {
    text-align: left;
    font-size: 1.1em;
    font-weight: bold;
}

.voucherinfo.invalid {
    color: red;
}

@media(min-width: 576px) {
    .panel-container > .input {
        width: 49%;
    }

    .panel-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }

    .panel-container > .input.full {
        width: 100%;
    }

    .ticket-picture {
        margin: 0;
    }
}

@media(min-width: 768px) {
    .double-container > .full {
        width: 100%;
    }
}

@media(min-width: 992px) {
    .content-container > .input-wrapper {
        flex: 1;
    }

    .content-container > .information-wrapper {
        min-width: 300px;
        width: 300px;
        margin-top: 0;
        margin-left: 4em;
    }
}
</style>